export default {
    name: 'address-fields',
    model: {
        prop: 'address',
    },
    props: {
        address: {
            type: Object,
        },
        readOnly: {
            type: Boolean,
            default: true,
        },
    },
};
