import λ from '~/utils/invoker';
import CreditFields from '~/credits/credit-fields.vue';
export default {
    name: 'credits-card',
    components: { CreditFields },
    props: {
        credits: Array,
        usedByType: {
            type: Object,
            default() { return {}; },
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        flat: {
            type: Boolean,
            default: false,
        },
        showModifier: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        selectedYear: λ.vuexState('year'),
        selectedCreditEntries() {
            return this.credits.filter(credit => credit.year === this.selectedYear);
        },
    },
};
