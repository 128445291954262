export default {
    name: 'contact-fields',
    model: {
        prop: 'contact',
    },
    props: {
        contact: {
            type: Object,
        },
        title: {
            type: String,
        },
        readOnly: {
            type: Boolean,
            default: true,
        },
    },
};
