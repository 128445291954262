import { CreditYears, creditTypes } from '#/credit';
export default {
    name: 'credit-fields',
    props: {
        credit: {
            type: Object,
        },
        used: {
            type: Number,
            default: 0,
        },
        showYear: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: true,
        },
        showModifier: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            CreditYears,
            creditTypes,
        };
    },
    computed: {
        remainingCredit() {
            var _a, _b;
            return !Number.isNaN(Number(this.used))
                ? Number((_a = this.credit) === null || _a === void 0 ? void 0 : _a.initial) + Number((_b = this.credit) === null || _b === void 0 ? void 0 : _b.modifier) - Number(this.used)
                : '...';
        },
        isValidRemaining() {
            return !Number.isNaN(Number(this.remainingCredit)) && Number(this.remainingCredit) > 0;
        },
    },
};
