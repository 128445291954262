import λ from '~/utils/invoker';
import { fetchAll } from '~/utils/crud-functions';
import parachute from '~/utils/parachute';
import { endOfYear, startOfYear } from 'date-fns';
import { getId } from '#/object-id';
import { map, prop } from 'ramda';
export default function creditForItemMixin(itemProp) {
    return {
        data() {
            return {
                usedCreditsByType: {},
                participantsByType: {},
            };
        },
        watch: {
            item: {
                handler() {
                    this.usedCreditsByType = {};
                    this.surveyParticipations();
                },
                immediate: true,
            },
            selectedCreditYear: {
                handler() {
                    this.usedCreditsByType = {};
                    this.surveyParticipations();
                },
                immediate: true,
            },
        },
        computed: {
            selectedCreditYear: λ.vuexState('year'),
            selectedCreditYearRange() {
                const base = new Date(this.selectedCreditYear, 0, 1);
                return {
                    start: startOfYear(base),
                    end: endOfYear(base),
                };
            },
            selectedCreditEntries() {
                var _a, _b;
                return (_b = (_a = this.item) === null || _a === void 0 ? void 0 : _a.credits) === null || _b === void 0 ? void 0 : _b.filter(credit => credit.year === this.selectedCreditYear);
            },
        },
        methods: {
            surveyParticipations: parachute(async function surveyParticipations() {
                if (!this.item) {
                    return;
                }
                const results = await fetchAll('participations/survey', {
                    filters: {
                        [itemProp]: getId(this.item),
                        range: this.selectedCreditYearRange,
                    },
                });
                this.usedCreditsByType = map(prop('credits'), results);
                this.participantsByType = map(prop('participants'), results);
            }),
        },
    };
}
;
